import React from 'react';

export default function PageFooter() {
  return (
    <div id="footer">
      <ul className="copyright">
        <li>&copy; K. Solovewicz. All rights reserved.</li>
        <li>
          Design: <a href="http://html5up.net">HTML5 UP</a>
        </li>
        <li>
          Avatar: <a href="http://kalepadot.com/">Jeremy Kale Padot</a>
        </li>
        <li>
          Photography: <a href="https://unsplash.com/@elena_kuchko?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Elena Kuchko</a>
        </li>
      </ul>
    </div>
  );
}
